import { io } from "socket.io-client";
import { DEL_ALL_CHATS, DEL_MSG_TO_READ, DEL_OVERRIDE_MSG, DEV_ID, HAS_CHAT, ID_CHAT, INSTANCE_CHAT, MESSAGES, RC, WS_URL } from "./glb";
import bcs from "./bcs";
import { toast } from "react-toastify";

const websocket = () => {
    try{
        const ws = io(WS_URL, {reconnectionAttempts: 1000000, reconnectionDelay: 1000});
        ws.on("connect", (socket) => {
            console.log(">> New web socket");
        });
        ws.on("/broadcast/ping_for_restgw_update", (data) => {
            try{
                if(data.restaurant_code == RC){
                    console.log(">> Running New GetBill");
                    bcs.main_f(false, () => {});
                }
            }catch(_){}
        });
        if(localStorage.getItem('use_department_chat') == "1"){
            ws.on("/broadcast/chat_message", (data) => {
                try{
                    if(data.to_device == DEV_ID){
                        
                        console.log(">> New Chat message");

                        toast(
                            <div>
                                <div style={{fontWeight: "bold"}}>Nuovo messaggio</div>
                                <div style={{color: "var(--green)", fontWeight: "bold"}}>Reparto {data.from_department}</div>
                                <div>{data.message}</div>
                            </div>, 
                            {type: "default", progressStyle: {background: "var(--green)", color: "var(--green)"}}
                        );

                        let upCounter = false;
                        if(!HAS_CHAT){ // Aggiorna solo contatore
                            upCounter = true;
                        }else{
                            
                            if(ID_CHAT == data.from_device){ // Aggiorna solo contenuto chat
                                
                                MESSAGES.push(data);
                                DEL_OVERRIDE_MSG(MESSAGES);

                                // Lancia up per quella chat
                                fetch(`http://217.160.159.28:9098/read_messages/${DEV_ID}/${data.from_device}`);

                                // Scrolla al massimo
                                setTimeout(() => {
                                    document.querySelector("#chat_area").scrollTop = 10000000;
                                }, 0);

                            }else{ // Aggiorna contatore e vista chat
                                DEL_ALL_CHATS();
                                upCounter = true; 
                            }

                        }

                        if(upCounter){
                            if(DEL_MSG_TO_READ){
                                DEL_MSG_TO_READ()
                            }
                        }

                    }
                }catch(_){}
            });
        }
    }catch(_){}
};

export default websocket;