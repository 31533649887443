import { useEffect, useState } from "react";

export default function LDish({dishes, filter_for, filter_id, status, click_event, remove_filter}){

    const [wrap_dish, set_wrap_dish] = useState([]);

    const wrap_event = (status) => {
        let _dishes = [];
        if(status){
            (dishes ?? []).filter(item => item.status == status.replace("view_", "") && item.ext_code && !item.is_carry).forEach((item) => {
                const index = (_dishes.findIndex(d => d.ext_code.toString() == item.ext_code.toString()));
                if ( index == -1 ){
                    item.global_quantity = parseInt(item.quantity);
                    _dishes.push(item);
                } else {
                    _dishes[index].global_quantity += parseInt(item.quantity);
                }
            });
        }

        // Ordina..
        const numeric = [];
        const alphanumeric = [];

        _dishes.forEach((item) => {
            if(isNaN(item.ext_code)){
                alphanumeric.push(item);
            }else{
                numeric.push(item);
            }
        });

        numeric.sort((a, b) => parseInt(a.ext_code) - parseInt(b.ext_code));
        alphanumeric.sort((a, b) => a.ext_code - b.ext_code);
        
        _dishes = [...numeric, ...alphanumeric];

        set_wrap_dish(_dishes);
    };

    useEffect(() => wrap_event(status), [dishes]);
    useEffect(() => wrap_event(status), [status]);

    return (
        <>
            {
                wrap_dish && wrap_dish.length > 0 ? 
                    wrap_dish.map((item, index) => 
                        <div class={`side-element ${ (filter_for == 'dish' && filter_id == item.ext_code ? 'active' : '')}`} key={`dish_${index}`} onClick={() => {
                            if(filter_for == 'dish' && filter_id == item.ext_code){
                                remove_filter();
                                return;
                            }
                         click_event(item.ext_code);
                        }}>{localStorage.getItem('sidebar_extended') == "1" ? item.description : item.ext_code} ({item.global_quantity ?? ""})</div>
                    )
                :
                    <></>
            }
        </>
    );
}