import { useEffect, useState } from "react";

const VaiAnim = ({callbackVaiAnim}) => {

    const [displayVai, setDisplayVai] = useState(false);

    useEffect(() => {
        callbackVaiAnim(setDisplayVai);
    }, []);

    useEffect(() => {
        if(displayVai){
            setTimeout(() => {
                setDisplayVai(false);
            }, 5000);
        }
    }, [displayVai]);

    return (
        <div style={{position: "absolute", zIndex: "9999", color: "red", fontSize: "100px", display: displayVai ? "block" : "none"}}>
            <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", background: "#0000004a"}}>
                <div className="pulse">C'è un nuovo "VAI"</div>
            </div>
        </div>
    );
};

export default VaiAnim;