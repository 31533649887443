import React from 'react';
import ReactDOM from 'react-dom/client';
import View1 from './v1';
import './style/index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { View3 } from './v3';
import { View4 } from './v4';
import View5 from './v5';

if(window.location.pathname !== "/"){
    window.location.href = "/";
}

//console.log = () => {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<View1/>}></Route>
            <Route path='/setting' element={<View3/>}></Route>
            <Route path='/products' element={<View4/>}></Route>
            <Route path='/carry' element={<View5/>}></Route>
        </Routes>
    </BrowserRouter>
);
