import { Base64 } from "js-base64";
import { B_AUTH, IN_DEBUG, MIDD_URL, RC, SC, set_ba } from "./glb";
import { xjs } from "./xjs";

const add_status = async (id, new_area_input, position, jump_to, is_start_view, visible = 1, print = 0, color = "", color_text = "", label_webapp = "", to_retire = 0, automatic_cancel = 0) => {

    const res_new_area = 
        await fetch(
            `${MIDD_URL}/api/v1/bcv/create_status`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id                  : id,
                    status_label        : new_area_input,
                    status_enum         : new_area_input.split(" ").join("_").toLowerCase(),
                    restaurant_code     : RC,
                    subscriber_code     : SC,
                    position            : position ?? 0,
                    change_status_to    : jump_to ?? new_area_input.split(" ").join("_").toLowerCase(),
                    is_start_view       : is_start_view,
                    visible             : visible,
                    print_receipt       : print,
                    hex_color           : color,
                    text_hex_color      : color_text,
                    web_app_description : label_webapp,
                    to_retire           : to_retire,
                    automatic_cancel    : automatic_cancel
                })
            }
        );
    
    return res_new_area;
};

const get_devices = async () => {

    const res_devices = await fetch(
        `${MIDD_URL}/api/v1/bcv/get_devices?restaurant_code=${RC}&subscriber_code=${SC}`,
    );

    return res_devices;

};

const get_devices_products = async () => {

    const res_devices_products = await fetch(
        `${MIDD_URL}/api/v1/bcv/get_devices_products?restaurant_code=${RC}&subscriber_code=${SC}`,
    );

    return res_devices_products;

};

const get_time_to_cancel = async () => {

    if(RC){
                        
        const response = 
            await fetch(
                `${MIDD_URL}/api/bcv/get_time_to_cancel?restaurant_code=${RC}`
            );

        if(response.status == 200){
            const json = await response.json();
            if(json.Result == "OK" && json.Entity){
                localStorage.setItem('time_to_cancel', json.Entity.time_to_clear.toString());
                localStorage.setItem('id_time_to_cancel', json.Entity.id.toString());
            }else{
                localStorage.setItem('id_time_to_cancel', '-1');
                localStorage.setItem('time_to_cancel', '-1');
            }
        }

    }

};

const save_device = async (code, id = null) => {

    const res_new_device = 
        await fetch(
            `${MIDD_URL}/api/v1/bcv/create_device`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id               : id,
                    code             : code,
                    restaurant_code  : RC,
                    subscriber_code  : SC,
                })
            }
        );
    
    return res_new_device;

};

const delete_device = async (id) => {

    try{
        const res_delete_device = 
            await fetch(`${MIDD_URL}/api/bcv/delete_device?id_device=${id}`);

        if(res_delete_device.status == 200){
            const json = await res_delete_device.json();
            if(json.Result == "OK"){
                return true;
            }
        }
    }catch(_){}

    return false;
};

const get_products = async () => {

    const subscriber = localStorage.getItem('subscriber_sync_product');
    let sc = SC;
    let ba = B_AUTH;
    if(subscriber == 'DylogVIDEOCOMANDE'){
        sc = "DylogVIDEOCOMANDE";
        ba = `${Base64.encode(sc)}:${Base64.encode("553147g9-128e-25ej-911b-66a21a355v5k")}`;
    }

    const res_products = 
        await fetch(`${MIDD_URL}/api/v1/bcv/get_products?restaurant_code=${RC}&subscriber_code=${sc}&auth=${ba}`);

    let products = [];
    if(res_products.status == 200){
        const json_products = await res_products.json();
        if(json_products.Result == "OK"){
            const xml = json_products.Xml;
            try{
                
                const document = xjs.create_xml_object(xml);
                const list = document.getElementsByTagName('CommerceInfo')[0].getElementsByTagName('ArticleList');
                (list[0].childNodes ?? []).forEach((article) => {
                    
                    const code  = article.getElementsByTagName('CodeArtExt')[0].textContent;

                    // Gestione gruppi 08.01.2024
                    let group = null;
                    try{
                        group = article.getElementsByTagName('Group')[0].textContent;
                    }catch(_){}

                    let name_group = null;
                    try{
                        name_group = article.getElementsByTagName('Name')[0].textContent;
                    }catch(_){}

                    products.push({
                        id          : article.getElementsByTagName('Code')[0].textContent,
                        description : article.getElementsByTagName('Description')[0].textContent + "  (" + code + ")", // Description
                        code        : code,
                        group       : group,
                        name_group  : name_group ?? "",
                        is_cover    : (article.getElementsByTagName('TipoRec')[0].textContent ?? "") == "CO",
                    }); 
                });

            }catch(e){
                if(IN_DEBUG){
                    console.log(e.message);
                }
            }
        }
    }
    
    return products;
};

const get_groups = async () => {

    const subscriber = localStorage.getItem('subscriber_sync_product');
    let sc = SC;
    let ba = B_AUTH;
    if(subscriber == 'DylogVIDEOCOMANDE'){
        sc = "DylogVIDEOCOMANDE";
        ba = `${Base64.encode(sc)}:${Base64.encode("553147g9-128e-25ej-911b-66a21a355v5k")}`;
    }

    const res_products = 
        await fetch(`${MIDD_URL}/api/v1/bcv/get_products?restaurant_code=${RC}&subscriber_code=${sc}&auth=${ba}`);

    let products = [];
    if(res_products.status == 200){
        const json_products = await res_products.json();
        if(json_products.Result == "OK"){
            const xml = json_products.Xml;
            try{
                
                const document = xjs.create_xml_object(xml);
                const list = document.getElementsByTagName('CommerceInfo')[0].getElementsByTagName('GroupList');
                (list[0].childNodes ?? []).forEach((group) => {
                    
                    const code  = group.getElementsByTagName('Code')[0].textContent;

                    // Gestione gruppi 08.01.2024
                    let description = null;
                    try{
                        description = group.getElementsByTagName('Description')[0].textContent;
                    }catch(_){}


                    products.push({
                        id          : code,
                        description : description, // Description
                    }); 
                });

            }catch(e){
                if(IN_DEBUG){
                    console.log(e.message);
                }
            }
        }
    }
    
    return products;

};

/**
 * 
 * @param {*} devices_products 
 * @returns 
 */
const save_device_product = async (devices_products) => {

    const res_devices_products = 
        await fetch(
            `${MIDD_URL}/api/v1/bcv/save_device_product?restaurant_code=${RC}&subscriber_code=${SC}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },  
                body: JSON.stringify({
                    devices_products: devices_products
                })
            }
        );

    return res_devices_products;
};

const save_work_in_progress = async (bill_id) => {

    const res_work_in_progress = 
        await fetch(`${MIDD_URL}/api/v1/bcv/add_work_in_progress?restaurant_code=${RC}&subscriber_code=${SC}&bill_id=${bill_id}`);

    return res_work_in_progress;
};

const set_done = async (unique_code, done) => {
    await fetch(`${MIDD_URL}/api/v1/bcv/set_quantity/done?unique_code=${unique_code}&quantity=${done}`);
};

const remove_from_work_in_progress = async (unique_code) => {
    await fetch(`${MIDD_URL}/api/v1/bcv/remove_from_work_in_progress?unique_code=${unique_code}`);
};

const check_lic = async (restaurant_code) => {
    try{
        const response = await fetch(`${MIDD_URL}/api/v1/bcv/lic?restaurant_code=${restaurant_code}&action=exists`);
        if(response.status == 200){
            const json = await response.json();
            if(json.Result == "ERR"){
                alert("Licenza non presente!!!");
                return false;
            }
            return true;
        }
    }catch(_){}
    alert("Licenza non presente!!!");
    return false;
};

const set_carry = async (data) => {

    try{

        const response = 
            await fetch(
                `${MIDD_URL}/api/v1/bcv/set_carry?restaurant_code=${RC}&subscriber_code=${SC}`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json"
                    },
                    body: JSON.stringify(data)
                }
            );

        if(response && response.status == 200){
            const json = await response.json();
            if(json.Result == "OK"){
                return true;
            }
        }

    }catch(_){}

    return false;
};

const get_carry = async () => {

    try{
        
        const response = 
            await fetch(`${MIDD_URL}/api/v1/bcv/get_carry?restaurant_code=${RC}&subscriber_code=${SC}`);

        if(response && response.status == 200){
            const json = await response.json();
            if(json.Result == "OK"){
                if(json.Carry){
                    return json.Carry;
                }
            }
        }

    }catch(_){}

    return [];
};

export {
    add_status,
    get_devices,
    save_device,
    get_products,
    save_device_product,
    get_devices_products,
    save_work_in_progress,
    set_done,
    remove_from_work_in_progress,
    check_lic,
    delete_device,
    set_carry,
    get_carry,
    get_groups,
    get_time_to_cancel
}