import { useEffect, useState } from "react";
import { ALERTS, set_alert } from "../glb";

const Alerts = ({exitCallback}) => {

    const [display, setDisplay] = useState("none");

    useEffect(() => {
        exitCallback(setDisplay);
    }, []);

    return (
        <div style={{position: "fixed", top: "0", left: "0", width: "300px", height: "100vh", overflow: "auto", zIndex: "99999", padding: "10px", background: "#00000090", display: display != "none" ? "block" : "none"}} key={display}>
            <div style={{color: "#fff", fontSize: "30px", background: "red", padding: "10px", borderRadius: "10px", display: "flex", justifyContent: "space-between", cursor: "pointer"}} onClick={() => {
                set_alert([]);
                setDisplay("none");
            }}>
                <div>Attenzione!</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                    </svg>
                </div>
            </div>
            {
                ALERTS && ALERTS.length > 0 ? 
                    ALERTS.map((alert, index) => 
                        <div style={{textAlign: "center", padding: "10px", background: "yellow", fontWeight: "bold", fontSize: "20px", marginTop :"10px", wordBreak: "break-word"}} key={`alert_${index}`}>
                            <div style={{width: "30px", height: "30px", borderRadius: "10000px", background: "red", color: "white", display: "flex", justifyContent: "center", alignItems: "center"}}>{index + 1}</div>
                            <div dangerouslySetInnerHTML={{__html: alert}}></div>
                        </div>
                    )
                :
                    <></>
            }
        </div>
    );
};

export default Alerts;