import { useEffect, useState } from "react";

export default function LTable({tables, filter_for, filter_id, click_event, txns, status, remove_filter, all_dishes_change, hookCommands}){

    const [filtered_tables, set_filtered_tables] = useState([]);

    const update = (txns, status) => {
        if(status){
            
            const tmp_tables = [];
            
            if(localStorage.getItem('monitor') == "command"){
                if(hookCommands && hookCommands.length > 0){
                    hookCommands.forEach(h => {

                        let qta = 0;
                        let codes = [];

                        h.lines.forEach(l => {
                            if(l.status == status){
                                qta += parseInt(l.quantity);
                                codes.push(l.ext_code);
                            }
                        });

                        tmp_tables.push({
                            id: h.id,
                            count: qta,
                            codes: codes,
                            waiting_time: h.waiting_time
                        });
                    });

                    set_filtered_tables(tmp_tables);
                    return;

                }
            }

            txns.forEach((item) => {

                // Controllo che non sia composta solo da riporti
                const indexNoCarry = item.lines.findIndex(l => !l.is_carry);
                if(indexNoCarry == -1){
                    return;
                }

                const len = item.lines.filter(line => line.status == status).length;
                if(len > 0){
                    const codes = [];
                    item.lines.filter(line => line.status == status).forEach(d => {
                        if(d.ext_code){
                           codes.push(d.ext_code);
                        }
                    });
                    tmp_tables.push({id: item.id, count: len, codes: [...codes], waiting_time: item.waiting_time});
                }

            });

            if(localStorage.getItem('order_table_by') == "time"){
                set_filtered_tables( (tmp_tables ?? []).sort((a, b) => b.waiting_time - a.waiting_time) );
            }else{
                const numeric = (tmp_tables ?? []).filter(item => !isNaN(item.id));
                const alphabetic = (tmp_tables ?? []).filter(item => isNaN(item.id));
                numeric.sort((a, b) => b.id - a.id);
                alphabetic.sort((a, b) => b.id - a.id);
                const tbls = [...numeric, ...alphabetic];
                set_filtered_tables(tbls);
            }
        }
    };

    useEffect(() => {
        update(txns, status);
    }, [txns]);

    useEffect(() => {
        update(txns, status);
    }, [status]);

    useEffect(() => {
        update(txns, status);
    }, [all_dishes_change]);

    return (
        <>
            {
                filtered_tables && filtered_tables.length > 0 ? 
                    filtered_tables.filter(item => (filter_for == 'dish' && filter_id ? item.codes.includes(filter_id) : item) && item.count > 0).map((item, index) => 
                        <div class={`side-element ${ (filter_for == 'table' && filter_id == item.id ? 'active' : '')}`} key={`table_${index}`} onClick={() => {
                            if(filter_for == 'table' && filter_id == item.id){
                                remove_filter();
                                return;
                            }
                            click_event(item.id);
                        }}>{item.id} ({item.count})<br/><small style={{color: "black", fontWeight: "bold"}}><i class="fas fa-clock" aria-hidden="true"></i>&nbsp;{parseInt(item.waiting_time)}'</small></div>
                    )
                :
                    <></>
            }
        </>
    );
}