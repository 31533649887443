import { useEffect, useState } from "react";
import { DEL_ALL_CHATS, DEVS, DEV_ID, MESSAGES, set_del_all_chats, set_del_override_msg, set_has_chat, set_id_chat, set_messages } from "../glb";

const Chat = ({close, removeToRead}) => {

    const [chats, setChats]                     = useState([]);
    const [messages, setMessages]               = useState([]);
    const [deviceSelected, setDeviceSelected]   = useState(-1);

    const overrideMessages = (messages) => {
        setMessages(JSON.parse(JSON.stringify(messages)));
    };

    const get_all_chats = () => {

        // Ottieni tutte le chats
        fetch(`http://217.160.159.28:9098/get_opened_chats/${DEV_ID}`)
        .then(async response => {
            if(response.status == 200){
                const json = await response.json();
                if(json.success){

                    // Controlla dei reparti locali quale manca
                    let data = (json.data ?? []);
                    DEVS.filter(d => d.id != DEV_ID).forEach(dep => {
                        const index = data.findIndex(d => d.from_device == dep.id);
                        if(index == -1){
                            data.push({
                                from_department: dep.code,
                                from_device: dep.id,
                                to_read: 0
                            });
                        }
                    });

                    setChats(
                        data.sort((a,b) => b.to_read - a.to_read)
                    )
                }
            }
        });

    };

    useEffect(() => {

        set_del_all_chats(get_all_chats);
        DEL_ALL_CHATS();
        set_has_chat(true);
        set_del_override_msg(overrideMessages);

        return (() => {
            set_has_chat(false);
            set_id_chat(-1);
        });

    }, []);

    useEffect(() => {
        set_messages(messages);
    }, [messages]);

    return (
        <div style={{display: "flex", position: "absolute", zIndex: "9999", width: "100vw", height: "100vh", top: "0", background: "rgb(0 0 0 / 38%)", justifyContent: "center", alignItems: "center"}}>

            <div style={{background: "#fff", width: "1000px", height: "600px", borderRadius: "20px", boxShadow: "rgb(0 0 0 / 8%) 0px 0px 10px 0px", display: "flex"}}>
                <div style={{width: "300px", height: "600px", background: "var(--green)", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", overflowY: "scroll"}}>
                    <div style={{fontSize: "30px", paddingLeft: "20px", paddingTop: "20px", color: "#fff", display: "flex", alignItems: "center", gap: "10px"}} onClick={() => {
                        close();
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                        </svg>
                        Chats
                    </div>

                    {
                        (chats ?? []).map((item, index) => 
                            <div key={`chat_${index}`} style={{paddingLeft: "20px", marginTop: "10px"}} onClick={async () => {
                                
                                setMessages([]);
                                removeToRead(item.to_read);
                                item.to_read = 0;
                                setDeviceSelected(item.from_device);
                                set_id_chat(item.from_device);

                                // Ottieni i messaggi
                                fetch(`http://217.160.159.28:9098/read_messages/${DEV_ID}/${item.from_device}`)
                                .then(async response => {
                                    if(response.status == 200){
                                        const json = await response.json();
                                        if(json.success){
                                            setMessages( (json.data ?? []).reverse() );
                                            setTimeout(() => {
                                                document.querySelector("#chat_area").scrollTop = 10000000;
                                            }, 0);
                                        }
                                    }
                                });

                            }}>
                                <div style={{width: "calc(100% - 20px)", height: "50px", border: "1px solid #ffffff", display: "flex", alignItems: "center", paddingLeft: "10px", borderRadius: "10px", color: "#fff", justifyContent: "space-between", paddingRight: "10px"}}>
                                    <div>{item.from_department} ({item.to_read})</div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
                {
                    (deviceSelected > -1) &&
                        <div style={{width: "700px", display: "flex", flexDirection: "column"}}>
                            <div style={{width: "700px", height: "530px", background: "#fff", borderTopRightRadius: "20px", borderBottomRightRadius: "20px", overflowY: "scroll", paddingBottom: "20px"}} id="chat_area">
                                <div style={{fontSize: "30px", paddingLeft: "20px", paddingTop: "20px", color: "#000", display: "flex", alignItems: "center", gap: "10px"}}>
                                    Ultimi 200 messaggi
                                </div>
                                {
                                    messages.map((item, index) => 
                                        <div style={{paddingLeft: "20px", paddingRight: "20px", display: "flex", justifyContent: item.from_device == DEV_ID ? "end" : "start"}} key={`message_${item.id}`}>
                                            <div style={{width: "350px", marginTop: "15px", padding: "10px", borderRadius: "10px", position: "relative", background: item.from_device == DEV_ID ? "var(--green)" : "#00000014", color: item.from_device == DEV_ID ? "#fff" : "#000"}}>
                                                <div style={{marginTop: "20px"}}>
                                                    {item.message}
                                                </div>
                                                <div style={{position: "absolute", top: "10px", right: "10px", fontWeight: "600", fontSize: "12px"}}>{item.created_at}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div style={{width: "700px", height: "70px", display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "20px", paddingRight: "20px", gap: "10px", borderTop: "1px solid #00000014"}}>
                                <input type="text" className="form-control" placeholder="Messaggio.." id="input_message"></input>
                                <button className="btn btn-primary" onClick={() => {

                                    const input = document.querySelector("#input_message");
                                    const value = input.value;
                                    if(value){
                                        
                                        messages.push({message: value, created_at: new Date().toISOString(), from_device: DEV_ID, to_device: deviceSelected});
                                        setMessages(JSON.parse(JSON.stringify(messages)));

                                        const index_ = DEVS.findIndex(d => d.id == DEV_ID);
                                        if(index_ > -1){
                                            const from_department = DEVS[index_].code ?? "";
                                            if(from_department){

                                                let payload = 
                                                    {
                                                        from_department: from_department,
                                                        message: value,
                                                        from_device: DEV_ID,
                                                        to_device: deviceSelected,
                                                        created_at: new Date().toISOString()
                                                    };

                                                // Invia messaggio
                                                fetch(
                                                    `http://217.160.159.28:9098/send_message`,
                                                    {
                                                        method: "POST",
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify(payload)
                                                    }
                                                ).then(async response => {

                                                    if(response.status == 200){
                                                        const json = await response.json();
                                                        if(json.sended){ // Chiama web socket
                                                            fetch(
                                                                `http://217.160.159.28:9098/broadcast/chat_message`,
                                                                {
                                                                    method: "POST",
                                                                    headers: {
                                                                        'Content-Type': 'application/json'
                                                                    },
                                                                    body: JSON.stringify(payload)
                                                                }
                                                            );
                                                        }
                                                    }

                                                });

                                            }
                                        }   

                                        input.value = "";
                                        setTimeout(() => {
                                            document.querySelector("#chat_area").scrollTop = 10000000;
                                        }, 0);
                                    }
                                    
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                }
            </div>

        </div>
    );
};

export default Chat;