import { useEffect, useState } from "react";

export default function Msg(){

    const [display, setDisplay] = useState(false);

    useEffect(() => {

        function hashChange(){
            setDisplay(window.location.hash == "#msg" && !display);
        }   

        window.addEventListener('hashchange', hashChange);

        return (() => {
            window.removeEventListener('hashchange', hashChange);
        });

    }, []);

    return (
        <div style={{position: "absolute", zIndex: "999999999999", background: "rgba(0 ,0, 0, 0.5)", top: "0", left: "0", width: "100vw", height: "100vh", display: display ? "" : "none"}}>
            <div style={{width: "350px", position: "absolute", left: "10px", zIndex: "9999999999999", top: "10px"}}>
                <div style={{height: "60px", background: "var(--green)", textAlign: "center", lineHeight: "60px", color: "white", fontSize: "25px", opacity: "1", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: "flex", justifyContent: "space-between"}}>
                    <div>
                        &nbsp;&nbsp;Messaggio
                    </div>
                    <div style={{cursor: "pointer"}} onClick={() => window.location.hash = ""}>
                        <i class="fas fa-sign-out-alt"></i>&nbsp;&nbsp;
                    </div>
                </div>
                <div style={{background: "white", padding: "10px", textAlign: "center", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}} id="body_modal"></div>
            </div>
        </div>
    );
}