import { useEffect, useState } from "react";
import Spin from "./spin";

export default function Summary({rows, view, set_all_dishes}){

    const [selected, set_selected] = useState([]);
    const [view_type, set_view_type] = useState('group');
    const [display_spin, set_display_spin] = useState(false);
    const [selected_exploded, set_selected_exploded] = useState([]);

    const select_all = () => {
        const dishes = [];
        selected.forEach((item) => {
            item.dishes.forEach((dish) => {
                if(selected_exploded.includes(dish.id.toString())){
                    dishes.push(dish);
                }
            });
        });
        return dishes;
    };

    const calculate_amount = () => {
        let qta = 0;
        rows.forEach(r => qta += parseInt(r.quantity));
        return qta;
    }

    return (
        <div className="card exploded">
            <Spin display={display_spin}/>
            <div className="card-header" style={{paddingTop: "10px", paddingBottom: "10px", fontWeight: "bold", color: "var(--green)", fontSize: "25px", display: "flex", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px"}}>
                <div>RIEPILOGO {view}</div> 
                <div>x{calculate_amount()}</div>
            </div>
            <div style={{height: "100%", overflow: "auto"}}>
                {
                    view_type == 'group' ? 
                        <>  
                            {
                                (rows ?? []).map((item, index) => {
                                    const indexInArray = selected.findIndex(i => i.ext_code == item.ext_code); 
                                    return (
                                        <div style={{display: "flex", justifyContent: "space-between", padding: "10px", border: indexInArray > -1 ? "2px solid var(--green)" : "none", cursor: "pointer", marginTop: "5px"}} key={`summary_${index}`} onClick={() => {
                                            const copy = [...selected];
                                            if(indexInArray > -1){
                                                copy.splice(indexInArray, 1);
                                            }else{
                                                copy.push(item);
                                            }
                                            set_selected(copy);
                                        }}>
                                            <div style={{width: "20%"}}><b>COD.</b>{item.ext_code ? item.ext_code : "..."}</div>
                                            <div style={{width: "60%", textAlign: "center"}}>{item.description}</div>
                                            <div style={{fontWeight: "bold", width: "20%", textAlign: "right"}}>x{item.quantity}</div>
                                        </div>
                                    );
                                })
                            }
                            <div style={{height: "100px"}}/>
                        </>
                    :
                        selected.map((s, index) => {
                            return (
                                s.dishes.map((d, d_index) => 
                                    <>
                                        <div style={{display: "flex", justifyContent: "space-between", padding: "10px", textAlign: "center", border: selected_exploded.includes(d.id.toString()) ? "2px solid var(--green)" : "none"}} onClick={() => {
                                            const s = [...selected_exploded];
                                            const index = s.findIndex(a => a.toString() == d.id.toString());
                                            if(index > -1){
                                                s.splice(index, 1);
                                            }else{
                                                s.push(d.id.toString());
                                            }
                                            set_selected_exploded(s);
                                        }}>
                                            <div style={{width: "15%", textAlign: "left"}}><b>COD.</b>{d.ext_code ? d.ext_code : "..."}</div>
                                            <div style={{width: "50%", textAlign: "left"}}>{d.description}</div>
                                            <div style={{width: "20%", textAlign: "right"}}><b>TAV.</b>{d.table_code}</div>
                                            <div style={{fontWeight: "bold", width: "15%", textAlign: "right"}}>x{d.quantity}</div>
                                        </div>
                                        <div style={{height: "5px"}}/>
                                    </>
                                )
                            );
                        })
                }
            </div>
            {
                selected.length > 0 && view_type == 'group' ? 
                    <div style={{position: "absolute",width: "100%", display: "flex", justifyContent: "end", borderTop: "1px solid rgb(220, 220, 220)", padding: "15px", background: "white", bottom: "0px"}}>
                        <button className="btn btn-primary" onClick={() => set_view_type('exploded')}>Avanti</button>
                    </div>
                :
                    view_type == 'exploded' ? 
                        <div style={{borderTop: "1px solid rgb(220, 220, 220)", height: "70px"}}>
                            <div style={{position: "absolute", bottom: "10px", left: "20px"}}>
                                <button className="btn btn-primary" onClick={() => set_view_type('group')}>Indietro</button>
                            </div>
                            <div style={{position: "absolute", bottom: "10px", right: "20px"}}>
                                <button className="btn btn-primary" onClick={() => {
                                    const all = select_all();
                                    set_all_dishes(all, 'next');
                                    document.querySelector('#div_backdrop').style.display = "none";
                                }}>Avanti</button>&nbsp;&nbsp;
                                {
                                    localStorage.getItem('enabled_in_work_button') == '1' ?
                                        <button className="btn btn-primary" onClick={() => {
                                            const all = select_all();
                                            set_all_dishes(all, 'work_in_progress');
                                            document.querySelector('#div_backdrop').style.display = "none";
                                        }}>In lavorazione</button>
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                    :
                        <></>
            }
        </div>
    );
}