import { useEffect, useState } from "react";
import { STATUS } from "../glb";

export default function Bc_Tab({opened, change_tab}){

    const [status, setStatus] = useState([]);

    const active_tab = () => {
        
        const _status = [];
        (STATUS ?? []).forEach((item) => {
            _status.push({
                label           : item.status_label,
                active_for      : item.status_enum,
                position        : item.position,
                visible         : item.visible == 1
            })
        });
        setStatus(_status);

    };

    useEffect(() => active_tab(), []);
    useEffect(() => active_tab(), [opened]);

    return (
        (status ?? []).sort((a, b) => a.position - b.position).filter(item => item.visible).map((item, index) => 
            <div className={`tab ${opened == item.active_for ? 'active' : ''}`} onClick={() => change_tab(item.active_for)}>{item.label.toUpperCase()}</div>
        )
    );
}