import { ALERTS, DEL_ALERT, TXNS, set_alert } from "./glb";

const runtime_alert = () => {

    const now = new Date();
    let messageForAlerts = [];

    if(TXNS && TXNS.length > 0){

        // Check prodotti in una comanda non gestiti dopo un certo numero di minuti
        try{
           if(localStorage.getItem('alert_minute_product_in_command') == "1"){
                let min = parseInt(localStorage.getItem('alert_minute_product_in_command_time'));
                if(min){
                    let prods = [];
                    for(let t = 0; t < TXNS.length; t++){
                        (TXNS[t].lines ?? []).forEach(l => {
                            if(l.status == "da_fare"){
                                const dateGet = new Date(l.date_get);
                                const diff = Math.abs(now - dateGet);
                                const minDiff = Math.floor((diff/1000)/60);
                                if(minDiff >= min){
                                    const index = prods.findIndex(p => p.table == TXNS[t].id);
                                    if(index == -1){
                                        prods.push({table: TXNS[t].id, msgs: [l.description.toString() + ` in attesa da ${minDiff} minuti`]});
                                    }else{
                                        prods[index].msgs.push(l.description.toString() + ` in attesa da ${minDiff} minuti `);
                                    }
                                }
                            }
                        });
                    }
                    
                    if(prods.length > 0){
                        // Componi messaggio
                        let msg = "";
                        prods.forEach(p => {
                            msg += `<b>Tavolo ${p.table}</b>: ${p.msgs.join(", ")}<br/>`;
                        });
                        messageForAlerts.push(msg);
                    }

                }
           } 
        }catch(_){}

        if(messageForAlerts.length > 0){
            messageForAlerts.forEach(m => ALERTS.push(m));
            DEL_ALERT(new Date().getTime());
        }

    }

    start_runtime_alert();

};

const start_runtime_alert = () => {
    setTimeout(() => runtime_alert(), 120000); // Ogni 2 min
};

export {
    start_runtime_alert
}