const epson = (to_print) => {

    const ip_address = localStorage.getItem('printer_ip_address');
    const ip_address_pc = localStorage.getItem('printer_ip_address_pc');

    let xml = `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body><epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">`;
    (to_print ?? []).forEach((txt) => {
        xml += '<text lang="it" smooth="true"/>';
        xml += '<text font="font_a"/>';
        xml += '<text align="center"/>';
        xml += `<text width="2" height="2">${txt.replace("\n", "")}&#10;</text>`;
    });
    
    for(let l = 0; l < 10; l++){ 
        xml += '<text lang="it" smooth="true"/>';
        xml += '<text font="font_a"/>';
        xml += '<text align="center"/>';
        xml += '<text width="2" height="2"></text>';
    }

    xml += `<cut type="feed"/></epos-print></s:Body></s:Envelope>`;

    fetch(
        `http://${ip_address_pc}:7000/protocols/epson/webservice/print`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "ip": ip_address,
                "xml": xml
            })
        }
    );

    //fetch(
    //    `http://${ip_address}/cgi-bin/epos/service.cgi?devid=local_printer`,
    //    {
    //        method: "POST",
    //        headers: {
    //            'Content-Type'      : 'text/xml; charset=utf-8',
    //            'If-Modified-Since' : 'Thu, 01 Jan 1970 00:00:00 GMT',
    //            'SOAPAction'        : '""'
    //        },
    //        body: xml
    //    }
    //);

};

const esc_pos = (lines) => {

    const ip_address = localStorage.getItem('printer_ip_address');
    const ip_address_pc = localStorage.getItem('printer_ip_address_pc');

    let commands = "INIT;";
    lines.forEach((item) => {
        commands += item + ";NEW_LINE;NEW_LINE;";
    });
    const date = new Date();
    const formatted_date = "NEW_LINE;NEW_LINE;" + "Ore: " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
    commands += formatted_date + ";NEW_LINE;NEW_LINE;"
    commands += "NEW_LINE;NEW_LINE;NEW_LINE;NEW_LINE";

    fetch(`http://${ip_address_pc}:7000/protocols/epson/esc_pos?ip=${ip_address}&commands=${encodeURIComponent( commands )}`);

};

export {
    epson, esc_pos
}