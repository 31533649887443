import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spin from "./gcomp/spin";
import { CARRY, DEV_PRDS, RC, SC } from "./glb";
import { get_devices, set_carry } from "./reuse";

export default function View5(){

    const navigate = useNavigate();
    const [devices_products, set_devices_products] = useState([]);
    const [carry, setCarry] = useState([]);
    const [display_spin, set_display_spin] = useState(false);
    const [init, set_init] = useState(false);

    useEffect(() => {

        if(init){
            const __carry = [];
            (CARRY ?? []).forEach(c => {
                const index = __carry.findIndex(_c => _c.id_device == c.id_device && _c.id_carry_device == c.id_carry_device);
                if(index == -1){
                    __carry.push({
                        id_device: c.id_device,
                        id_carry_device: c.id_carry_device
                    });
                }
            });
            setCarry(__carry);
        }

    }, [init]);

    useEffect(() => {

        // Ottieni tutti i devices
        get_devices().then(async (response) => {
            if(response.status == 200){
                const json = await response.json();
                if(json.Result == "OK"){
                    const devices = json.Devices ?? [];
                    if(devices.length > 0){
                        devices.forEach(d => {
                            d.products = [];
                            const prds = DEV_PRDS.filter(dp => dp.id_device.toString() == d.id.toString());
                            prds.forEach(p => {
                                d.products.push(p.id_product);
                            });
                        });
                        if(devices.findIndex(d => d.products.length > 0) > -1){
                            const copy = [...devices.filter(i => i.products.length > 0)];
                            set_devices_products(copy);
                        }
                        set_init(true);
                    }
                }
            }
        });

    }, []);

    return (
        <>
            <Spin display={display_spin}/>
            <div class="settings-page">    
                <div style={{fontSize: "30px", color: "red", fontWeight: "bold", cursor: "pointer"}} onClick={() => navigate(-1)}>
                    <i class="fas fa-sign-out-alt" ></i>&nbsp;&nbsp;Indietro
                </div>
                <p>
                    Indicato in verde è il reparto su cui si vuole effettuare il riporto di un altro reparto.<br/>
                    <span style={{fontWeight: "bold", color: "red"}}>***Attenzione:</span><span style={{fontWeight: "bold"}}> quando si aggiornano i prodotti in un reparto salvare nuovamente 
                    il riporto per tenere aggiornati tutti i riferimenti!</span>
                </p>
                {
                    devices_products && devices_products.length > 0 ? 
                        <>
                            {
                                devices_products.map((item, index) =>
                                    <div style={{display: "flex", justifyContent: "space-between", borderBottom: "1px solid #0000002e", padding: "10px"}} key={`linea_riporto_${index}`}>
                                        <div style={{width: "200px", background: "green", color: "white", textAlign: "center", padding: "10px", borderRadius: "10px"}}>{item.code}</div>
                                        {
                                            devices_products.filter(dp => dp.id != item.id).map((_item, _index) => {
                                                const indexCarry = carry.findIndex(c => c.id_device == item.id && c.id_carry_device == _item.id);
                                                return (
                                                    <div style={{width: "200px", padding: "10px", display: "flex"}}  key={`linea_riporto_${index}_${_index}`}>
                                                        <div style={{height: "20px", display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                                            <input type={"checkbox"} style={{width: "20px", height: "20px"}} checked={indexCarry > -1} onClick={() => {

                                                                const copy = [...carry];
                                                                if(indexCarry > -1){
                                                                    copy.splice(indexCarry, 1);
                                                                }else{
                                                                    copy.push({id_device: item.id, id_carry_device: _item.id});
                                                                }
                                                                setCarry(copy);

                                                            }}/>
                                                        </div>
                                                        <div style={{height: "20px", lineHeight: "20px"}}>
                                                            &nbsp;&nbsp;{_item.code}
                                                        </div>
                                                    </div>
                                                );
                                            })  
                                        }
                                    </div>
                                )
                            }
                            <div style={{width: "100%", display: "flex", justifyContent: "end"}}>
                                <button className="btn btn-danger" style={{width: "250px", height: "60px"}} onClick={(async () => {

                                    set_display_spin(true);
                                    let data = [];
                                    if(carry && carry.length > 0){

                                        const backend_data_put = [];
                                        carry.forEach(c => {
                                            const index = devices_products.findIndex(i => i.id == c.id_carry_device);                                        
                                            if(index > -1){
                                                if(devices_products[index].products.length > 0){
                                                    devices_products[index].products.forEach(dp => {
                                                        backend_data_put.push({
                                                            restaurant_code: RC,
                                                            subscriber_code: SC,
                                                            id_device: c.id_device,
                                                            id_carry_device: c.id_carry_device,
                                                            id_product: dp
                                                        });
                                                    });
                                                }
                                            }
                                        });
                                        data = [...backend_data_put];
                                        
                                    }

                                    const res = await set_carry(data);
                                    set_display_spin(false);
                                    if(!res){
                                        alert("Errore creazione riporti!");
                                        return;
                                    }
                                    window.location.href = "/";

                                })}>AGGIORNA TUTTI I RIPORTI</button>
                            </div>
                        </>
                    :
                        <div style={{fontSize: "20px", fontWeight: "bold"}}>Nessun reparto disponibile per riporto..</div>
                }
            </div>
        </>
    );
}