import { useEffect, useState } from "react";
import { DEVS, DEV_PRDS, RC, SC } from "./glb";
import { get_devices, get_groups, get_products, save_device_product } from "./reuse";

const View4 = () => {

    const [products, set_products]  = useState([]);
    const [devices, set_devices]    = useState([]);
    const [render, set_render]      = useState(false);
    const [groups, setGroups]       = useState([]);

    useEffect(() => {

        (async () => {

            const _devices  = await get_devices();
            if(_devices && _devices.status == 200){
                const json_devices = await _devices.json();
                if(json_devices.Result == "OK"){

                    set_devices(json_devices.Devices);
                    
                    const for_product_structure = [];
                    json_devices.Devices.forEach((item) => {
                        for_product_structure.push({
                            id: item.id,
                            checked: false
                        })
                    });

                    const _products = await get_products();
                    const _groups = (await get_groups()) ?? [];

                    _products.forEach((item) => {
                        item.devices = [...JSON.parse(JSON.stringify(for_product_structure))]; // Parse e deparse per creare nuova locazione in memoria
                    });

                    (DEV_PRDS ?? []).forEach((item) => {
                        const filtered = _products.filter(p => p.id.toString() == item.id_product.toString());
                        if(filtered && filtered.length > 0){
                            const filtered_devices = filtered[0].devices.filter(d => d.id.toString() == item.id_device.toString());
                            if(filtered_devices && filtered_devices.length > 0){
                                filtered_devices[0].checked = true;
                            }
                        }
                    });

                    // Distinct dei gruppi
                    try{
                        
                        let groups_ = [];
                        _products.forEach(p => {
                            if(p.group){
                                const index = groups_.findIndex(g => g == p.group);
                                if(index == -1){
                                    groups_.push(p.group); //  + " => " + (p.name_group ?? "")
                                }
                            }
                        });

                        // Filtro su oggetto gruppi
                        for(let g = 0; g < groups_.length; g++){
                            const group = groups_[g];
                            const index = _groups.findIndex(gg => gg.id == group);
                            if(index > -1){
                                groups_[g] = groups_[g] + " ==> " + _groups[index].description;
                            }
                        }

                        setGroups(groups_);

                    }catch(_){}

                    set_products(_products);
                }
            }

        })();

    }, []);

    return (
        <div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">PRODOTTI</th>
                        {
                            devices && devices.length > 0 ? 
                                devices.map((device, index) => 
                                    <th scope="col" key={`th_${index}`}>
                                        {device.code}&nbsp;&nbsp;
                                        <input type={"checkbox"} className="form-check-input" onChange={(e) => {
                                            products.forEach(p => {
                                                p.devices[index].checked = e.target.checked;
                                            });
                                            set_render(!render);
                                        }}/>
                                    </th>
                                )
                            :
                                <></>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        products && products.length > 0 ? 
                            products.map((product, index) => 
                                <tr key={`product_${index}`}>
                                    <td>#{product.id} {product.description}</td>
                                    {
                                        product.devices.map((p_device, index) => {
                                            return (
                                                <td key={`product_${product.id}_${p_device.id}`}>
                                                    <input type={"checkbox"} className="form-check-input" checked={p_device.checked} onChange={(e) => {
                                                        p_device.checked = !p_device.checked;
                                                        set_render(!render);
                                                    }}/>
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            )
                        :
                            <></>
                    }
                </tbody>
            </table>
            <div class="table-controller">
                <button className="btn btn-primary" onClick={async () => {
                    
                    const flat = [];
                    const filtered = products.filter(product => product.devices.filter(device => device.checked).length > 0);
                    if(filtered.length > 0){
                        filtered.forEach((f) => {
                            const devices = f.devices.filter(item => item.checked);
                            devices.forEach((dev) => {

                                if(dev.checked){
                                    flat.push(
                                        {
                                            id_device       : parseInt(dev.id),
                                            id_product      : parseInt(f.id),
                                            ext_code        : f.code,
                                            restaurant_code : RC,
                                            subscriber_code : SC
                                        }
                                    );
                                }

                            });
                        });
                    }
                    
                    const res_devices_products = await save_device_product(flat);
                    if(res_devices_products && res_devices_products.status == 200){
                        const json_devices_products = await res_devices_products.json();
                        if(json_devices_products.Result == "OK"){
                            window.location.href = "/";
                        }
                    }

                }}>Salva</button>
                <button className="btn btn-primary" onClick={async () => {

                    const new_products = [];
                    (products ?? []).forEach((prd) => {
                        (prd.devices ?? []).forEach((dev) => dev.checked = true);
                        new_products.push(prd);
                    });
                    set_products(new_products);

                }}>Seleziona tutto</button>
                <button className="btn btn-primary" onClick={async () => {

                    const new_products = [];
                    (products ?? []).forEach((prd) => {
                        (prd.devices ?? []).forEach((dev) => dev.checked = false);
                        new_products.push(prd);
                    });
                    set_products(new_products);

                }}>Rimuovi tutto</button>

                <div style={{marginLeft: "50px", display: "flex", gap: "10px", alignItems: "center"}}>
                    <div style={{fontWeight: "bold"}}>-- Macro gruppi prodotto --</div>
                    <div>
                        <select className="form-select" id="select-group">
                            {
                                groups.map((item, index) => <option value={item} key={`option_${index}`}>Gruppo {item}</option>)
                            }
                        </select>
                    </div>
                    <div>
                        <select className="form-select" id="select-device">
                            {
                                DEVS.map((item, index) => 
                                    <option value={item.id} key={`option_device_${index}`}>{item.code}</option>
                                )
                            }
                        </select>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => {
                            
                            const select_group = document.querySelector('#select-group');
                            const select_device = document.querySelector('#select-device');

                            if(select_group && select_device){
                                const value_group = select_group.value.split(" ")[0].trim();
                                const value_device = select_device.value;
                                products.filter(i => i.group == value_group).forEach(g => {
                                    const indexDevice = g.devices.findIndex(d => d.id.toString() == value_device);
                                    if(indexDevice > -1){
                                        g.devices[indexDevice].checked = true;
                                    }
                                });
                                const prds = JSON.parse( JSON.stringify( products ) );
                                set_products(prds);
                            }

                        }}>APPLICA MACRO</button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export {
    View4
}